<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <div class="search_bar">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="filter.dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Заезд - выезд"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            height="48"
            v-on="on"
            hide-details
            outlined
            dense
            background-color="#fff"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.dates"
          :first-day-of-week="1"
          no-title
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(filter.dates)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <people-count :model="filter" />
      <v-btn
        color="primary"
        @click="search"
        height="48"
        width="160"
        class="ml-4"
      >
        Найти
      </v-btn>
    </div>
    <div class="nav_items">
      <p v-scroll-to="'#services'">
        <img src="@/assets/images/booking/residence/bell.svg" alt="" />
        Услуги
      </p>
      <p v-scroll-to="'#residence'">
        <img src="@/assets/images/booking/residence/docks.svg" alt="" />
        Порядок проживания
      </p>
      <p v-scroll-to="'#rooms'">
        <img src="@/assets/images/booking/residence/residence.svg" alt="" />
        Доступные номера
      </p>
      <p v-scroll-to="'#reviews'">
        <img src="@/assets/images/booking/residence/star.svg" alt="" />
        Отзывы
      </p>
    </div>
    <div class="residence_title">
      <p class="residence_title__name" v-text="details.name"></p>
      <div>
        <v-icon size="24" color="#FFC738">mdi-star</v-icon>
        <p v-text="details.rating"></p>
      </div>
    </div>
    <p class="location_name">
      <img src="@/assets/images/ui/location_orange.svg" alt="" />{{
        details.place_name
      }}
    </p>
    <div class="residence_header">
      <div>
        <div
          v-if="details.images"
          @click="galleryDialog = true"
          class="residence_header__gallery"
        >
          <div
            v-for="(item, idx) in details.images.slice(0, 4)"
            :key="idx"
            :style="{ backgroundImage: `url(${item.image})`, ...item.style }"
          >
            <div v-if="idx == 0" class="loop">
              <v-icon color="primary" size="36">mdi-magnify</v-icon>
            </div>
            <div v-if="idx == 3" class="gallery_count">
              +{{ details.images.length }} фото
            </div>
          </div>
        </div>
        <div class="residence_header__text" v-html="details.description"></div>
      </div>
      <div class="map">
        <p class="map__title">
          <v-icon color="primary" size="20">mdi-map-marker</v-icon>
          Адрес
        </p>
        <p class="map__adress" v-text="details.place_name"></p>
        <div @click="showMap" class="map__box">
          <div>
            <v-icon color="primary" size="20">mdi-map-search</v-icon>
            <p>Развернуть карту</p>
          </div>
          <yandex-map
            :controls="[]"
            style="width: 100%; height: 166px"
            :coords="[details.latitude, details.longitude]"
            :zoom="5"
          >
          </yandex-map>
        </div>
        <v-dialog v-model="mapDialog" width="700">
          <yandex-map
            :controls="[]"
            style="width: 100%; height: 500px"
            :coords="[details.latitude, details.longitude]"
            :zoom="5"
          >
            <ymap-marker
              :coords="[details.latitude, details.longitude]"
              marker-id="123"
              hint-content=""
            />
          </yandex-map>
        </v-dialog>
      </div>
    </div>
    <transition name="dialog">
      <div v-if="galleryDialog" class="pop_wrapper">
        <v-icon
          class="close_icon"
          @click="closeGallery"
          color="primary"
          size="60"
          >mdi-close</v-icon
        >
        <div class="pop_wrapper__big_swiper">
          <v-icon
            @click="onNavClick('slidePrev')"
            style="cursor: pointer"
            color="primary"
            size="60"
            >mdi-chevron-left</v-icon
          >
          <swiper ref="myBigSwiper" :options="bigSwiperOptions">
            <swiper-slide
              v-for="(img, idx) in details.images"
              :key="idx"
              :style="{ backgroundImage: `url(${img.image})` }"
            >
            </swiper-slide>
          </swiper>
          <v-icon
            @click="onNavClick('slideNext')"
            style="cursor: pointer"
            color="primary"
            size="60"
            >mdi-chevron-right</v-icon
          >
        </div>
        <div class="pop_wrapper__small_swiper">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(img, idx) in details.images" :key="idx">
              <div
                :style="{ backgroundImage: `url(${img.image})` }"
                @click="onSlideClick(idx)"
              ></div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </transition>

    <p id="services" class="residence_title">Услуги</p>
    <div class="box_wrapper" v-html="details.description_services"></div>
    <p id="residence" class="residence_title">порядок проживания</p>
    <div class="box_wrapper" v-html="details.description_accommodation"></div>
    <div>
      <span style="display: inline-block" id="rooms" class="residence_title"
        >Доступные номера</span
      >
      <v-btn style="float: right;position:sticky;top: 70px;right: 0px;z-index:4;" color="primary" @click="reserve"
        >Забронировать</v-btn
      >
      <residence-rooms
        v-for="(item, index) in rooms"
        :key="index"
        :item="item"
        :filter="filter"
        :details="details"
        :discounts="discounts"
        @sendDiscounts="takeDiscounts"
      />
    </div>
    <!-- <p id="reviews" class="residence_title mt-6">Отзывы</p>
    <p class="review_count">17 отзывов</p>
    <review-box v-for="i in 2" :key="i + 'i'" />
    <div class="text-center">
      <v-pagination v-model="page" :length="4" circle></v-pagination>
    </div> -->
  </div>
</template>

<script>
import vueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import peopleCount from "@/components/ui/peopleCount.vue";
import residenceRooms from "@/components/booking/residence/residenceRooms.vue";
// import reviewBox from "@/components/booking/residence/reviewBox.vue";
import { accommodationService } from "@/services/accommodation.service.js";
export default {
  components: {
    vueBreadcrumbs,
    peopleCount,
    residenceRooms,
    // reviewBox,
  },
  data: () => ({
    page: 1,
    details: {},
    bigSwiperOptions: {
      centeredSlides: true,
      slidesPerView: 1,
    },
    swiperOptions: {
      slidesPerView: "auto",
      centeredSlides: true,
    },
    rooms: [],
    galleryDialog: false,
    mapDialog: false,
    menu: false,
    filter: {
      adult: 1,
      child: 0,
      city: null,
      dates: [],
    }, 
    discounts: [
      {
        id: 1, 
        name: 'Скидка 1',
        price: 1000,
      },
      {
        id: 2, 
        name: 'Скидка 2',
        price: 2000,
      },
      {
        id: 3, 
        name: 'Скидка 3',
        price: 3000,
      },
      {
        id: 4, 
        name: 'Скидка 4',
        price: 4000,
      },
    ],
    discounts_booking: {},
  }),
  computed: {
    breadCrumbs(){
      return  [
        {
          text: "Бронирование проживания",
          to: "/booking/residence",
          query: this.$route.query,
          disabled: false,
        },
        {
          text: this.details.room,
          to: `/booking/residence/${this.$route.params.id}`,
          query: this.$route.query,
          disabled: true,
        },
      ]
    },
    dateRangeText() {
      if (this.filter.dates.length == 2) {
        if (this.filter.dates[0] > this.filter.dates[1])
          this.filter.dates.reverse();
      }
      return this.filter.dates.join(" ~ ");
    },
  },
  created() {
    this.filter = { ...this.filter, ...this.$route.query, dates: [ this.$route.query.start, this.$route.query.end ], child: +this.$route.query.child, adult: +this.$route.query.adult }
    if(this.$route.query.rating) this.filter.rating = [ ...this.$route.query.rating ]
    this.filter.accommodation_types = this.$route.query.accommodation_types
    if(this.filter.accommodation_types) {
      if(typeof this.filter.accommodation_types == 'object'){
        this.filter.accommodation_types = this.filter.accommodation_types.join(',')
      }
    }
    if(this.filter.rating) {
      if(typeof this.filter.rating == 'object'){
        this.filter.rating = this.filter.rating.join(',')
      }
    }
    if(this.filter.price_range){
      if(typeof this.filter.price_range == 'object'){
        this.filter.price_range = this.filter.price_range.join(',')
      }
    }
    if(this.filter.places){
      if(typeof this.filter.places == 'object'){
        this.filter.places = this.filter.places.join(',')
      }
    }
    if(this.filter.feeds){
      if(typeof this.filter.feeds == 'object'){
        this.filter.feeds = this.filter.feeds.join(',')
      }
    }
    this.getDetails();
    this.getRooms();
    this.getDiscounts()
  },
  methods: {
    async getDiscounts(){
      let res = await accommodationService.getDiscounts(this.$route.params.id)
      res.forEach(e => {
        e.price = e.quantity
        delete e.quantity
      })
      this.discounts = res
    },
    takeDiscounts(value){
      this.discounts_booking[value.item] = value.data
      console.log(this.discounts_booking);
    },
    search() {
      this.$router.push({ name: this.$route.name, query: { ...this.filter } })
      this.getRooms();
      this.getDetails();
    },
    async reserve() {
      if (!this.details.is_work || !this.details.has_room) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Нельзя зарезвировать!",
          color: "error",
        });
        return;
      }
      let body = {
        check_in: this.filter.dates[0] + " " + this.rooms[0].check_in_time,
        check_out: this.filter.dates[1] + " " + this.rooms[0].check_out_time,
        tour_operator:
          this.$store.getters["auth/userRole"] == "operator"
            ? this.$store.getters["auth/getUser"].id
            : null,
        chosen_rooms: [],
        discounts: []
      };
      // подсчитать discounts
      let discounts_all = []
      for(const [key, value] of Object.entries(this.discounts_booking)){
        value.forEach(e => {
          discounts_all.push(e)
        })
      } 
      let only_ids = []
      discounts_all.forEach(e => {
        if(!only_ids.includes(e.discount)) only_ids.push(e.discount)
      })
      let discounts = []
      only_ids.forEach(e => {
        let quantity_sum = 0
        discounts_all.forEach(j => {
          if(e == j.discount){
            quantity_sum += j.quantity
          }
        })
        discounts.push({
          discount: e, 
          quantity: quantity_sum
        })
      })
      body.discounts = discounts
      // подсчитать discounts
      
      this.rooms.forEach((e) => {
        if (e.is_added) {
          e.counts.forEach((j) => {
            body.chosen_rooms.push({
              category: e.id,
              rooms: 1,
              adult_count: j.adult,
              child_count: j.child,
            });
          });
        }
      });
      let res = await accommodationService.createReserve(body);
      let ids = [];
      res.forEach((e) => {
        ids.push(e.booking_id);
      });
      this.$router.push({
        path: `/booking/residence/${this.$route.params.id}/booking/`,
        query: {
          id: ids,
          ...this.$route.query,
        },
      });
    },
    async getRooms() {
      this.rooms = [];
      let params = {
        ...this.filter,
        start: this.filter.dates[0],
        end: this.filter.dates[1],
        adult_count: +this.filter.adult,
        child_count: +this.filter.child,
      };
      delete params.dates;
      let res = await accommodationService.getCategories(
        params,
        this.$route.params.id
      );
      res.results.forEach((e, index) => {
        e.count = 1;
        if (!e.room_count) e.count = 0;
        e.adult = 0;
        e.child = 0;
        e.counts = [
          {
            adult: 0,
            child: 0,
          },
        ];
      });
      this.rooms = res.results;
    },
    async getDetails() {
      let params = {
        ...this.filter,
        start: this.filter.dates[0],
        end: this.filter.dates[1],
        adult_count: +this.filter.adult,
        child_count: +this.filter.child,
      };
      delete params.dates;
      let res = await accommodationService.getAccommodation(
        params,
        this.$route.params.id
      );
      this.details = res;
      this.setGallery();
      this.breadCrumbs[1] = {
        text: this.details.name,
        to: `/booking/residence/${this.$route.params.id}`,
        query: this.$route.query,
        disabled: true,
      };
    },
    onNavClick(method) {
      this.$refs.myBigSwiper.$swiper[method]();
      this.$refs.mySwiper.$swiper[method]();
    },
    onSlideClick(idx) {
      this.$refs.myBigSwiper.$swiper.slideTo(idx);
      this.$refs.mySwiper.$swiper.slideTo(idx);
    },
    showMap() {
      this.mapDialog = true;
    },
    closeGallery() {
      this.galleryDialog = false;
    },
    setGallery() {
      if (this.details.images.length >= 4) {
        this.details.images[0].style = {
          gridColumn: "1 / 3",
          gridRow: "1 / 3",
        };
        this.details.images[1].style = {
          gridColumn: "3 / 5",
          gridRow: "1 / 2",
        };
      } else if (this.details.images.length == 3) {
        this.details.images[0].style = {
          gridColumn: "1 / 3",
          gridRow: "1 / 3",
        };
        this.details.images[1].style = {
          gridColumn: "3 / 5",
          gridRow: "1 / 2",
        };
        this.details.images[2].style = {
          gridColumn: "3 / 5",
          gridRow: "2 / 3",
        };
      } else if (this.details.images.length == 2) {
        this.details.images[0].style = {
          gridColumn: "1 / 3",
          gridRow: "1 / 3",
        };
        this.details.images[1].style = {
          gridColumn: "3 / 5",
          gridRow: "1 / 3",
        };
      } else if (this.details.images.length == 1) {
        this.details.images[0].style = {
          gridColumn: "1 / 5",
          gridRow: "1 / 3",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search_bar {
  display: grid;
  grid-template-columns: 1fr 1fr 176px;
  align-items: center;
  padding: 10px 32px;
  background-color: rgba($color: #52b1ba, $alpha: 0.2);
  box-shadow: 0px 10px 20px rgba(138, 149, 146, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
}
.nav_items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba($color: #52b1ba, $alpha: 0.2);
  width: 100%;
  height: 37px;
  padding: 10px 32px;
  margin-bottom: 30px;
  p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 60px;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 0;
    cursor: pointer;
    img {
      margin-right: 12px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.residence_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
  &__name {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    margin-right: 6px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      margin-left: 1px;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }
}
.location_name {
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  grid-column-gap: 6px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 20px;
}
.residence_header {
  display: grid;
  grid-template-columns: 1fr 251px;
  align-items: start;
  grid-column-gap: 32px;
  &__gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 260px;
    border-radius: 5px;
    overflow: hidden;
    grid-gap: 20px;
    div {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      cursor: pointer;
      .loop {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .gallery_count {
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5)
        );
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
  }
  &__text {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .map {
    p {
      margin-bottom: 0;
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #52b1ba;
      img {
        margin-right: 6px;
      }
    }
    &__adress {
      margin-top: 10px;
      margin-left: 22px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    &__box {
      position: relative;
      margin-top: 20px;
      box-shadow: 0px 10px 20px rgba(82, 177, 186, 0.2);
      border-radius: 4px;
      overflow: hidden;
      div {
        background: linear-gradient(
          0deg,
          rgba(233, 233, 233, 0.7),
          rgba(233, 233, 233, 0.7)
        );
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        justify-content: center;
        p {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #52b1ba;
          margin-top: 9px;
        }
      }
    }
  }
}
.box_wrapper {
  padding: 30px 36px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(138, 149, 146, 0.1);
  border-radius: 4px;
  margin-top: 12px;
  font-size: 16px;
  line-height: 19px;
  color: #021011;
  margin-bottom: 50px;
}
.residence_title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
  color: #001239;
  margin-bottom: 25px !important;
}
.dialog-enter-active,
.dialog-leave-active {
  transition: 0.18s;
}

.dialog-enter,
.dialog-leave-to {
  transform: scale(0.2);
  opacity: 0;
}
.sub_images {
  .mini_swiper_slide {
    width: auto;
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__main {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__single {
    height: 100px;
    width: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.pop_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .close_icon {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
  &__big_swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
    width: 1200px;
    max-width: 85%;
    max-height: 60%;
    margin-bottom: 5%;
    .swiper-container {
      width: 100%;
      height: 100%;
      margin: 0 20px;
      .swiper-slide {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  &__small_swiper {
    max-width: 70%;
    .swiper-container {
      .swiper-slide {
        width: auto;
        margin-right: 20px;
        &:last-of-type {
          margin-right: 0;
        }
        div {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
.review_count {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 19px;
  color: #021011;
}
</style>
