<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel class="mb-5" readonly>
      <v-expansion-panel-header class="panel_header" hide-actions>
        <div
          class="avatar"
          @click="dialog = true"
          :style="{ backgroundImage: `url(${item.main_image || img})` }"
        >
          <div v-if="item.images.length > 0" class="avatar__count">
            <p>1/{{ item.images.length }}</p>
          </div>
        </div>
        <div class="description">
          <div class="description__text">
            <p class="description__text__title" v-text="item.name"></p>
            <p
              class="description__text__descr"
              :style="[
                checkHeight && limitDesc
                  ? { '-webkit-line-clamp': 30 }
                  : { '-webkit-line-clamp': 3 },
              ]"
              v-text="item.description"
            ></p>
            <strong
              class="desctiprion_button"
              v-if="checkHeight"
              @click="limitDesc = !limitDesc"
              >{{ limitDesc ? "Развернуть" : "Свернуть" }}
              <v-icon small color="#0071c2">{{
                limitDesc ? "mdi-arrow-down" : "mdi-arrow-up"
              }}</v-icon>
            </strong>
            <div class="tags">
              <p
                v-for="(tag, index) in item.tags"
                :key="index"
                v-text="tag.text"
              ></p>
            </div>
            <div class="extra" v-if="item.adult_count + item.child_count > 0">
              <p>Есть {{ item.adult_count + item.child_count }} доп. места</p>
              <v-btn
                color="primary"
                outlined
                width="150"
                height="40"
                @click="onToggle"
              >
                <img
                  class="mr-2"
                  src="@/assets/images/booking/residence/residence.svg"
                  alt=""
                />
                Доп. место
              </v-btn>
            </div>
          </div>
          <div class="description__actions">
            <p class="description__actions__price">{{ getPrice }} KZT</p>
            <p class="description__actions__count">
              {{ roomCounter(item.room_count) }}
            </p>
            <div>
              <v-btn
                :disabled="item.count == 1 || !item.room_count"
                @click="changeCount(-1)"
                icon
              >
                <v-icon size="30" color="primary"
                  >mdi-minus-circle-outline</v-icon
                >
              </v-btn>
              <p v-text="item.count"></p>
              <v-btn
                :disabled="item.count == item.room_count || !item.room_count"
                @click="changeCount(1)"
                icon
              >
                <v-icon size="30" color="primary"
                  >mdi-plus-circle-outline</v-icon
                >
              </v-btn>
            </div>
            <div style="flex-direction: column">
              <v-btn
                color="primary"
                outlined
                width="180"
                height="44"
                @click="addDiscount"
              >
                Скидки
              </v-btn>
              <v-btn
                color="primary"
                class="mt-3"
                outlined
                width="180"
                height="44"
                @click="onClick"
              >
                Выбрать
              </v-btn>
            </div>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="panel_content">
        <div v-for="(itemCount, index) in item.counts" :key="index">
          <div
            class="people_count"
            v-if="item.adult_count + item.child_count > 0"
          >
            <p class="people_count__title">Добавить доп. место</p>
            <div class="people_count__wrapper">
              <div
                v-if="item.adult_count"
                class="people_count__wrapper__single"
              >
                <p class="people_count__wrapper__single__title">
                  {{ item.adult_price.toLocaleString("KZ-kz") }} KZT
                </p>
                <div>
                  <p>Взрослый</p>
                  <p>Старше {{ item.child_age }} лет</p>
                </div>
                <div>
                  <v-btn
                    :disabled="itemCount.adult == 0"
                    @click="changeAdditional(-1, 'adult', index)"
                    icon
                  >
                    <img src="@/assets/images/ui/minusFilled.svg" alt="" />
                  </v-btn>
                  <p v-text="itemCount.adult"></p>
                  <v-btn @click="changeAdditional(1, 'adult', index)" icon>
                    <img src="@/assets/images/ui/plusFilled.svg" alt="" />
                  </v-btn>
                </div>
              </div>
              <div
                v-if="item.child_count"
                class="people_count__wrapper__single"
              >
                <p class="people_count__wrapper__single__title">
                  {{ item.child_price.toLocaleString("KZ-kz") }} KZT
                </p>
                <div>
                  <p>Детский</p>
                  <p>До {{ item.child_age }} лет</p>
                </div>
                <div>
                  <v-btn
                    :disabled="itemCount.child == 0"
                    @click="changeAdditional(-1, 'child', index)"
                    icon
                  >
                    <img src="@/assets/images/ui/minusFilled.svg" alt="" />
                  </v-btn>
                  <p v-text="itemCount.child"></p>
                  <v-btn @click="changeAdditional(1, 'child', index)" icon>
                    <img src="@/assets/images/ui/plusFilled.svg" alt="" />
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-dialog width="700" v-model="dialog">
      <v-carousel>
        <v-carousel-item
          v-for="(image, idx) in item.images"
          :key="idx"
          :src="image.image"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-dialog v-model="discount_here" max-width="500">
      <v-card class="pa-8">
        <h2 class="mb-4" style="color: #52b1ba">Добавить скидку</h2>
        <v-select
          @change="handleChangeDiscount"
          v-model="discounts_now"
          item-text="name"
          item-value="id"
          :items="discounts"
          multiple
          label="Выберите скидку"
        >
        </v-select>
        <div
          class="mt-3"
          v-for="(item, index) in discounts_booking"
          :key="index"
        >
          <div class="discount_info">
            <div>
              <p v-text="item.name"></p>
              <p v-text="item.price.toLocaleString('KZ-kz') + ' KZT'"></p>
            </div>
            <v-btn @click="deleteDiscount(index)" icon
              ><v-icon color="error">mdi-delete</v-icon></v-btn
            >
          </div>
          <div class="discount_actions">
            <v-btn
              :disabled="item.quantity == 1"
              @click="changeDiscount(-1, index)"
              icon
            >
              <v-icon size="30" color="primary"
                >mdi-minus-circle-outline</v-icon
              >
            </v-btn>
            <p v-text="item.quantity"></p>
            <v-btn @click="changeDiscount(1, index)" icon>
              <v-icon size="30" color="primary">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>

        <v-btn
          color="primary"
          outlined
          width="180"
          height="44"
          @click="saveDiscount"
          >Сохранить</v-btn
        >
      </v-card>
    </v-dialog>
  </v-expansion-panels>
</template>

<script>
import { accommodationService } from "@/services/accommodation.service.js";
export default {
  props: {
    item: Object,
    filter: Object,
    details: Object,
    discounts: Array,
  },
  data: () => ({
    img: require("@/assets/images/test/test.jpg"),
    panel: 0,
    dialog: false,
    limitDesc: false,
    discount_here: false,
    discounts_now: [],
    discounts_booking: [],
    discounts_booking_arr: [],
  }),
  computed: {
    getPrice() {
      let price = this.item.accommodation_price * this.item.count;
      this.item.counts.forEach((element) => {
        price += element.adult * this.item.adult_price;
        price += element.child * this.item.child_price;
      });
      return price.toLocaleString("KZ-kz");
    },
    checkHeight() {
      if (this.item.description) {
        return this.item.description.length > 170;
      }
      return false;
    },
  },
  methods: {
    saveDiscount() {
      console.log(this.item);
      let arr = this.discounts_booking.map((e) => {
        return { discount: e.id, quantity: e.quantity };
      });
      this.$emit("sendDiscounts", { data: arr, item: this.item.id });
      this.discount_here = false;
    },
    deleteDiscount(index) {
      let id = this.discounts_booking[index].id;
      this.discounts_booking.splice(index, 1);
      this.discounts_now = this.discounts_now.filter((e) => e != id);
      this.discounts_booking_arr = this.discounts_booking_arr.filter(
        (e) => e != id
      );
    },
    changeDiscount(numb, index) {
      let quan = this.discounts_booking[index].quantity + numb;
      delete this.discounts_booking[index].quantity;
      this.$set(this.discounts_booking[index], "quantity", quan);
    },
    handleChangeDiscount(value) {
      this.discounts.forEach((e) => {
        if (
          value.includes(e.id) &&
          !this.discounts_booking_arr.includes(e.id)
        ) {
          this.discounts_booking_arr.push(e.id);
          this.discounts_booking.push({ ...e, quantity: 1 });
        }
      });
      let dif = this.discounts_booking_arr.filter(
        (e) => !this.discounts_now.includes(e)
      );
      dif.forEach((e) => {
        this.discounts_booking.forEach((j, index) => {
          if (j.id == e) this.discounts_booking.splice(index, 1);
        });
        this.discounts_booking_arr.forEach((j, index) => {
          if (j == e) this.discounts_booking_arr.splice(index, 1);
        });
      });
    },
    addDiscount() {
      if (
        !this.details.is_work ||
        !this.details.has_room ||
        !this.item.room_count
      ) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Нельзя добавить скидки!",
          color: "error",
        });
        return;
      }
      this.discount_here = true;
    },
    changeAdditional(numb, type, index) {
      if (!this.item.room_count) return;
      if (numb < 0) {
        this.$set(
          this.item.counts[index],
          type,
          this.item.counts[index][type] + numb
        );
      } else {
        if (type == "adult") {
          if (
            this.item.counts[index].child + this.item.counts[index].adult ==
              this.item.adult_count + this.item.child_count ||
            this.item.counts[index].adult == this.item.adult_count
          )
            return;
          else {
            this.$set(
              this.item.counts[index],
              "adult",
              this.item.counts[index].adult + numb
            );
          }
        } else {
          if (
            this.item.counts[index].child < this.item.child_count ||
            (this.item.counts[index].adult != this.item.adult_count &&
              this.item.counts[index].child + this.item.counts[index].adult <
                this.item.adult_count + this.item.child_count)
          ) {
            this.$set(
              this.item.counts[index],
              "child",
              this.item.counts[index].child + numb
            );
          }
        }
      }
    },
    changeCount(numb) {
      if (!this.item.room_count) return;
      if (numb > 0) {
        this.item.counts.push({
          adult: 0,
          child: 0,
        });
      } else {
        this.item.counts.pop();
      }
      this.item.count += numb;
    },
    onToggle() {
      if (this.panel) {
        this.panel = 0;
        return;
      }
      this.panel = 1;
    },
    roomCounter(room) {
      if (!room) return `Не осталось номеров`;
      if (room == 1) return `остался ${room} номер`;
      if ((room >= 21 && room <= 24) || (room >= 2 && room <= 4))
        return `осталось ${room} номера`;
      return `осталось ${room} номеров`;
    },
    async onClick() {
      if (!this.details.is_work || !this.details.has_room) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Нельзя зарезвировать!",
          color: "error",
        });
        return;
      }
      if (!this.item.room_count) return;
      this.item.is_added = true;
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Добавлено!",
        color: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.discount_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 3px;
  }
  p:nth-child(2) {
    color: #06b04a;
  }
}
.discount_actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  p {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0 10px;
  }
}
.desctiprion_button {
  background: white;
  color: #0071c2;
  display: block;
  margin-bottom: 2vw;
  padding-bottom: 1vw;
  cursor: pointer;
}
.panel_header {
  display: grid;
  grid-template-columns: 193px 1fr;
  grid-column-gap: 20px;
  align-items: start;
  cursor: default;
  p {
    margin-bottom: 0;
  }
  .avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 193px;
    border-radius: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px;
    cursor: pointer;
    &__count {
      padding: 4px 7px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        margin: 0;
      }
    }
  }
  .description {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__text {
      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #001239;
      }
      &__descr {
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-top: 12px;
        max-width: 85%;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tags,
      .extra {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .tags {
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-top: 12px;
        margin-bottom: 30px;
        p {
          position: relative;
          margin-right: 30px;
          &:first-of-type {
            &::before {
              display: none;
            }
          }
          &::before {
            content: "";
            position: absolute;
            top: 6px;
            left: -17px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #ff8413;
          }
        }
      }
      .extra {
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ff8413;
          margin-right: 20px;
        }
      }
    }
    &__actions {
      text-align: center;
      &__price {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #001239;
      }
      &__count {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ff8413;
        margin-top: 10px;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 6px;
        margin-bottom: 10px;
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
          margin: 0 6px;
        }
      }
    }
  }
}
.people_count {
  border-top: 1px solid #e9e9e9;
  padding: 30px 14px 20px;
  margin: 0 10px;
  p {
    margin-bottom: 0;
  }
  &__title {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #021011;
  }
  &__wrapper {
    margin-top: 26px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    &__single {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
      }
      div {
        margin-left: 20px;
        &:first-of-type {
          p {
            &:first-of-type {
              font-size: 16px;
              line-height: 19px;
              color: #021011;
              margin-bottom: 6px;
            }
            &:last-of-type {
              font-size: 10px;
              line-height: 12px;
              color: #cfd7e3;
            }
          }
        }
        &:last-of-type {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 20px;
          p {
            margin: 0 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #021011;
          }
        }
      }
    }
  }
}
</style>
