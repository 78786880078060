<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="countText()"
                prepend-inner-icon="mdi-account"
                height="48"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                outlined
                dense
                background-color="#fff"
            ></v-text-field>
        </template>
        <div class="count_card" :key="refreshKey">
            <div class="card_content">
                <div class="action_item">
                    <div>
                        <p>Взрослые</p>
                        <p>Старше 12 лет</p>
                    </div>
                    <div>
                        <v-btn 
                            icon
                            @click="onClick(-1, 'adult')"
                        >
                            <img src="@/assets/images/ui/minusFilled.svg" alt="">
                        </v-btn>
                            <p>{{model.adult}}</p>
                        <v-btn 
                            icon
                            @click="onClick(1, 'adult')"
                        >
                            <img src="@/assets/images/ui/plusFilled.svg" alt="">
                        </v-btn>
                    </div>
                </div>
                <div class="action_item">
                    <div>
                        <p>Дети</p>
                        <p>До 12 лет</p>
                    </div>
                    <div>
                        <v-btn 
                            icon
                            @click="onClick(-1, 'child')"
                        >
                            <img src="@/assets/images/ui/minusFilled.svg" alt="">
                        </v-btn>
                            <p>{{model.child}}</p>
                        <v-btn 
                            icon
                            @click="onClick(1, 'child')"
                        >
                            <img src="@/assets/images/ui/plusFilled.svg" alt="">
                        </v-btn>
                    </div>
                </div>
                <div v-if="isPensioner" class="action_item mt-3">
                    <div>
                        <p>Пенсионеры</p>
                        <p></p>
                    </div>
                    <div>
                        <v-btn 
                            icon
                            @click="onClick(-1, 'pensioner')"
                        >
                            <img src="@/assets/images/ui/minusFilled.svg" alt="">
                        </v-btn>
                            <p>{{model.pensioner}}</p>
                        <v-btn 
                            icon
                            @click="onClick(1, 'pensioner')"
                        >
                            <img src="@/assets/images/ui/plusFilled.svg" alt="">
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-menu>
</template>

<script>
export default {
    props: {
        isPensioner: Boolean,
        model: Object
    },
    data: () => ({
        adult: 1,
        child: 0,
        pensioner: 0,
        refreshKey: 0,
        menu: false
    }),
    methods: {
        countText() {
            if (this.isPensioner) {
                return `${this.model.adult} взр. ${this.model.child} детей ${this.model.pensioner} пенс.`
            }
            return `${this.model.adult} взрослых ${this.model.child} детей`
        },
        onClick(num, type) {
            if (this.model[`${type}`]+num < 0) {
                return
            }
            this.model[`${type}`]+=num
            this.refreshKey++
        }
    }
}
</script>

<style lang="scss" scoped>
.count_card {
    width: 264px;
    padding: 20px 0px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(138, 149, 146, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .action_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-of-type {
            margin-bottom: 12px;
        }
        div {
            &:first-of-type {
                p {
                    margin-bottom: 0;
                    &:first-of-type {
                        font-family: 'Gilroy', sans-serif;
                        font-size: 16px;
                        line-height: 19px;
                        color: #021011;
                        margin-bottom: 6px;
                    }
                    &:last-of-type {
                        font-family: 'Gilroy', sans-serif;
                        font-size: 10px;
                        line-height: 12px;
                        color: #CFD7E3;
                    }
                }
            }
            &:last-of-type {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 18px;
                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #021011;
                    margin: 0 3px;
                }
            }
        }
    }
}
</style>