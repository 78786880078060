import axios from "axios";
const url = '/accommodation/'

export const accommodationService = {
    // форма бронирования
    async addClient(body) {
        return (await axios.post(`${url}add-clients/`, body)).data
    },
    // список номеров в зоне отдыха
    async getCategories(params, company_slug) {
        return (await axios.get(`${url}category-list/${company_slug}/`, {
            params
        })).data
    },
    // главный поиск
    async getCompanies(params) {
        return (await axios.get(`${url}company-list/`, {
            params
        })).data
    },
    // взять ближайшие
    async getNearest(params) {
        return (await axios.get(`${url}nearest/`, {
            params
        })).data
    },
    // сделать резерв
    async createReserve(body) {
        return (await axios.post(`${url}reserve/`, body)).data
    },
    // детали зоны отдыха
    async getAccommodation(params, company_slug) {
        return (await axios.get(`${url}${company_slug}/`, {
            params
        })).data
    },
    // взять список городов
    async getCities() {
        return (await axios.get(`${url}cities/`)).data
    },
    // взять список подместностей
    async getSubPlaces(company_slug) {
        return (await axios.get(`${url}places/${company_slug}/`)).data
    },
    // взять резервы
    async getReserves(params) {
        return (await axios.get(`${url}cards/`, {
            params
        })).data
    },
    // взять еду на фильтр
    async getFeed() {
        return (await axios.get(`${url}feeds/`)).data
    },
    // взять скидки 
    async getDiscounts(id){
        return (await axios.get(`${url}discounts/${id}/`)).data
    },
    // взять доп сервисы
    async getServices(id){
        return (await axios.get(`${url}services/${id}/`)).data
    },  
    // запостить доп сервисы 
    async postServices(body){
        return (await axios.post(`${url}services/`, body)).data
    },
}